import { type ISystemWaterway, type IBusinessAccountService, type IBusinessServiceType, BusinessServiceTypeEnum, type IBusinessAccount } from '@aiq/types';
import { createContext, useContext } from 'react';
import { setCookie } from 'cookies-next';
import { unique } from '@/lib/utils/collectionUtils';
import Spinner from '@/components/spinner';
import { type GroupedService } from '@/types';

interface AccountServicesContextProps {
	systemWaterways: ISystemWaterway[];
	businessAccounts: IBusinessAccount[];
	services: IBusinessAccountService[];
	groupedServicesForBusinessAccount: (businessAccountId?: number) => GroupedService[];
	servicesForBusinessAccount: (businessAccountId?: number) => IBusinessAccountService[] | null;
	servicesBySystemWaterway: Record<string, IBusinessServiceType[]>;
	update: () => Promise<IBusinessAccountService[] | undefined>;
	getBusinessAccountBySystemWaterwayAndServiceType: (systemWaterwayId: number, serviceType: BusinessServiceTypeEnum) => IBusinessAccount | undefined;
}

interface AccountServicesProviderProps {
	children: React.ReactNode;
	services: IBusinessAccountService[] | null;
}

const AccountServicesContext = createContext<AccountServicesContextProps>({
	systemWaterways: [],
	businessAccounts: [],
	services: [],
	servicesBySystemWaterway: {},
	servicesForBusinessAccount: () => null,
	groupedServicesForBusinessAccount: () => [],
	update: () => Promise.resolve(undefined),
	getBusinessAccountBySystemWaterwayAndServiceType: () => undefined
});

export function AccountServicesProvider({ children, services }: AccountServicesProviderProps) {
	// const { data: services, mutate } = useApi<IBusinessAccountService[]>(isLoggedIn ? getServicesByBusinessUser() : null, {
	// 	revalidateOnFocus: false,
	// 	fallbackData: initialServices
	// });

	const update = () => Promise.resolve(undefined); //mutate();

	if (!services) {
		return (
			<div className='min-h-screen w-full flex items-center justify-center'>
				<Spinner />
			</div>
		);
	}

	const businessAccounts = unique<IBusinessAccount>(
		services.map(x => x.business_account),
		'id'
	);

	setCookie('businessAccounts', JSON.stringify(businessAccounts));

	const systemWaterways = unique<ISystemWaterway>(
		services.map(x => x.system_waterway),
		'id'
	);

	const servicesBySystemWaterway = services.reduce<Record<string, IBusinessServiceType[]>>((acc, service) => {
		const key = service.system_waterway.id;
		if (!(key in acc)) {
			acc[key] = [];
		}
		acc[key].push(service.business_service_type);
		return acc;
	}, {});

	const servicesForBusinessAccount = (businessAccountId?: number) => (businessAccountId ? services.filter(x => x.business_account.id === businessAccountId) : null);

	const getBusinessAccountBySystemWaterwayAndServiceType = (systemWaterwayId: number, serviceType: BusinessServiceTypeEnum) =>
		services.find(x => x.system_waterway.id === systemWaterwayId && x.business_service_type.id === (serviceType as number))?.business_account;

	const groupedServicesForBusinessAccount = (businessAccountId?: number) => {
		const servicesForAccount = servicesForBusinessAccount(businessAccountId);
		const groupedServices = servicesForAccount?.reduce<Record<string, GroupedService>>((acc, service) => {
			const isWaterwayService =
				(service.business_service_type.id as BusinessServiceTypeEnum) === BusinessServiceTypeEnum.LOGBOOK ||
				(service.business_service_type.id as BusinessServiceTypeEnum) === BusinessServiceTypeEnum.BOOKINGS;

			const key = isWaterwayService ? service.system_waterway.name : service.business_service_type.display_name;

			if (!(key in acc)) {
				acc[key] = {
					name: key,
					system_waterway_id: service.system_waterway.id,
					isWaterwayService,
					subServices: [],
					href: ''
				};
			}
			if (isWaterwayService) {
				acc[key].subServices.push(service.business_service_type);
			}
			return acc;
		}, {});

		const result = Object.values(groupedServices || {}).map(x => {
			const href = x.isWaterwayService
				? `/${businessAccountId}/${x.subServices.find(ser => (ser.id as BusinessServiceTypeEnum) === BusinessServiceTypeEnum.LOGBOOK) ? 'logbooks' : 'permits'}/${x.system_waterway_id}`
				: `/${businessAccountId}/${x.name}`;

			return {
				...x,
				subServices: x.subServices.sort((a, b) => a.id - b.id),
				href
			};
		});

		return result as GroupedService[];
	};

	return (
		<AccountServicesContext.Provider
			value={{
				getBusinessAccountBySystemWaterwayAndServiceType,
				groupedServicesForBusinessAccount,
				servicesForBusinessAccount,
				businessAccounts,
				systemWaterways,
				servicesBySystemWaterway,
				services,
				update
			}}
		>
			{children}
		</AccountServicesContext.Provider>
	);
}

export function useAccountServices() {
	return useContext(AccountServicesContext);
}
