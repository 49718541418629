'use client';

import React, { useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { Formik } from 'formik';
import Image from 'next/image';
import { Button, Input, ErrorMessage } from '@aiq/ui-web';
import Link from 'next/link';
import { useTranslation } from '@/lib/i18n/client';
import { signIn } from '@/lib/authActions';
import { useAccountServices } from '@/context/accountServicesContext';

interface ErrorsObject {
	email?: string;
	password?: string;
}

function LoginForm() {
	const [serverError, setServerError] = useState<string | null>(null);
	const [loggingIn, setLoggingIn] = useState(false);

	const { update: updateServices } = useAccountServices();

	const router = useRouter();
	const searchParams = useSearchParams();

	const { t } = useTranslation(['common', 'errors']);

	return (
		<div className='min-h-screen min-w-screen -mt-header flex flex-col items-center justify-center'>
			<div className='bg-white shadow-xl max-w-md w-full py-8 px-8 rounded-xl items-center flex flex-col'>
				<Image alt='Angling iQ - Business Logo' className='py-2' height={60} src='/svg/business_logo.svg' width={150} />
				<h2 className='self-start font-semibold text-xl mt-8'>{t('auth.login')}</h2>
				<Formik
					initialValues={{ email: '', password: '' }}
					onSubmit={async values => {
						setServerError(null);
						setLoggingIn(true);

						const body: {
							password: string;
							email: string;
						} = {
							password: values.password,
							email: values.email
						};

						try {
							const res = await signIn('credentials', {
								redirect: false,
								...body
							});

							if (res === null) {
								await updateServices();
								return router.replace(searchParams.get('callbackUrl') || '/');
							}

							const errorCode = typeof res === 'object' && res.error.code;

							if (errorCode === 40106) {
								setServerError(t('auth.invalidPasswordEmailCombination', { ns: 'errors' }));
							} else if (errorCode === 40402) {
								setServerError(t('auth.userNotFound', { ns: 'errors' }));
							} else {
								setServerError(t('auth.errorLoggingIn', { ns: 'errors' }));
							}
						} catch (e) {
							setServerError(t('auth.errorLoggingIn', { ns: 'errors' }));
						}

						setLoggingIn(false);
					}}
					validate={values => {
						const errors: ErrorsObject = {};
						setServerError(null);
						if (!values.email) {
							errors.email = t('email.required', { ns: 'errors' });
						} else if (values.email.length < 2 || values.email.length > 100 || !values.email.includes('@')) {
							errors.email = t('email.invalid', { ns: 'errors' });
						}

						if (!values.password) {
							errors.password = t('password.required', { ns: 'errors' });
						} else if (values.password.length < 6) {
							errors.password = t('password.minLength', { ns: 'errors' });
						} else if (values.password.length > 50) {
							errors.password = t('password.maxLength', { ns: 'errors' });
						}

						return errors;
					}}
				>
					{({ handleSubmit, values, touched, errors, handleChange }) => (
						<form className='flex flex-col w-full mt-4' onSubmit={handleSubmit}>
							<Input error={errors.email && touched.email ? errors.email : undefined} name='email' onChange={handleChange} title={t('email')} type='text' value={values.email} />
							<Input
								className='mt-4'
								error={errors.password && touched.password ? errors.password : undefined}
								name='password'
								onChange={handleChange}
								title={t('password')}
								type='password'
								value={values.password}
							/>
							<Link className='text-sm mt-2 ml-auto text-primary' href='/password/forgot'>
								{t('auth.forgotPassword', { ns: 'common' })}
							</Link>
							{serverError ? <ErrorMessage className='mt-4' text={serverError} /> : null}
							<Button className='mt-8' loading={loggingIn} type='submit' variant='blue'>
								{t('auth.logIn')}
							</Button>
						</form>
					)}
				</Formik>
			</div>
		</div>
	);
}

export default LoginForm;
