
// import is from 'date-fns/locale/is';
// import en from 'date-fns/locale/en-US';
// import formatDate from 'date-fns/format';

// const locales = { is, en };

export const availableLocales = ['is', 'en'];

export default {
	locales: availableLocales,
	defaultLocale: 'en',
	keySepararator: '.',
	nsSeparator: '.',
	pages: {
		'*': ['common']
	},
	interpolation: {
		formatSeparator: ',',
		// format: (value, format, lng) => {
		// 	if (Date.parse(value)) {
		// 		const locale = locales[lng];
		// 		return formatDate(new Date(value), format, { locale });
		// 	}
		// }
	}
};
