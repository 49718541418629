import type { UseTranslationOptions } from "react-i18next";
import { useTranslation as useTranslationOriginal } from "react-i18next";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { initTranslations } from ".";

const runsOnServerSide = typeof window === 'undefined';

initTranslations();

export function useTranslation(ns: string | string[] | undefined = 'common', options?: UseTranslationOptions<string>) {
	const { t, i18n } = useTranslationOriginal(ns, options);
	const pathname = usePathname();
	const [locale, setLocale] = useState<string>(pathname.split('/')[1]);

	if (runsOnServerSide) {
		i18n.changeLanguage(locale);
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			const _locale = pathname.split('/')[1];
			setLocale(_locale);
		}, [pathname]);

		if (locale !== i18n.resolvedLanguage) {
			i18n.changeLanguage(locale);
		}

		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (locale !== i18n.resolvedLanguage) {
				i18n.changeLanguage(locale);
			}
		}, [locale]);
	}

	return { t, i18n };
}